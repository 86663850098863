declare module 'highcharts' {
  interface Options {
    datasetIds?: [];
  }
}

export function generateChartOptions(responseData = []) {
  let options = []
  if (Array.isArray(responseData)) {
    responseData.forEach((graph: any) => {
      options = [...options, generateSingleChartOptions(graph)];
    });
  }
  return options
}

export function generateSingleChartOptions(responseData: any): Highcharts.Options {
  const { title, chart, xAxis, yAxis, series, goalSeriesData } = responseData;

  let minWidth:number = 500;
  xAxis.categoryCount > 35 ? minWidth = 10000 : minWidth;

  const seriesOptions = getSeriesOptions(chart,series);

  let trendlineSeriesOptions: Highcharts.SeriesOptionsType[] = [];

  if (chart.isFragmentSizeRangeChart) {
      seriesOptions.forEach((seriesOption: Highcharts.SeriesOptionsType, index) => {
          const seriesName = series[index].name;
          if (seriesName === "Fines < 10.0mm" || seriesName === "Fines < 0.39in") {
              pushTrendline(seriesName, series[index].data, trendlineSeriesOptions, 'dash');
              if (goalSeriesData.data.length) {
                  pushTrendline(seriesName + ' Goal', goalSeriesData.data, trendlineSeriesOptions, 'dash', 'lightGreen');
              }
          }
      });
  }
  
  let xAxisLabels: any = {};
  if (title.text == "Explosive Cost Per Ton by Site" && chart.type === 'column') {
    xAxisLabels = {
      rotation: -45
    };
  }
  
  const chartOptions: Highcharts.Options = {
    chart: {
      type: chart.type,
      style: {
        fontFamily: 'Lato'
      },
      scrollablePlotArea: {
        scrollPositionX: 1,
        opacity:1
      },
      zooming: {
        type: chart.zoomType
      },
    },
    tooltip: {
      formatter: function () {
        if (chart.isFragmentSizeRangeChart) {
          return `
            <p>
              <span width='20' height='20' style="font-size:16px;color:${this.series.color}">●</span>
              <span><strong>${this.series.name}</strong>: ${this.y}%</span>
            </p>
          `;
        }
        else {
          return `
          <p>
            <span>${this.x}</span>
            <span width='20' height='20' style="font-size:16px;color:${this.series.color}">●</span>
            <span><strong>${this.series.name}</strong>: ${this.y}</span>
          </p>
        `;
        }
      },
      style: {
        color: '#333333',
        cursor: 'default',
        fontSize: '1.5em'
      }
    },
    title: {
      text: title.text,
      align: 'left'
    },
    xAxis: {
      categories: xAxis?.categories,
      title: {
        text: title.text,
        style: {
          fontSize: '1rem'
        }
      },
      labels: {
        rotation:30,
        style: {
          fontSize: '1rem',
          distance: '0px'
        },     
      },
    },
    yAxis: {
      title: {
        text: yAxis?.title.text,
        style: {
          fontSize: '1rem'
        }
      },
      labels: {
        style: {
          fontSize: '.8rem'
        }
      },
    },
    plotOptions: {
      pie:{
        dataLabels: {
          format: '{point.percentage:.1f}%',
        }
      },
    },  
    credits: {
      enabled: false
    },
	series: [...seriesOptions, ...trendlineSeriesOptions],
	accessibility: {
		enabled: true
	},
  datasetIds: chart.isFragmentSizeRangeChart && goalSeriesData?.datasetIds?.length ? goalSeriesData.datasetIds: [],
};

if (minWidth > 500) {
	chartOptions.navigator = {
		enabled: true,
		xAxis: {
			labels: {
				enabled: false
			}
		}
	};
	chartOptions.scrollbar = {
		enabled: true,
		barBackgroundColor: '#5271ff',
	};
} else {
	chartOptions.navigator = {
		enabled: false
	};
}

if (chart.isFragmentSizeRangeChart) {
    chartOptions.plotOptions = {
        ...chartOptions.plotOptions,
        column: {
            stacking: 'percent',
        },
    };
}

	return chartOptions;
}

export function getSeriesOptions(chart,series) {
  let seriesOptions:[] = [];
    
  if (chart.type === 'pie') {
    seriesOptions = series.map(series => ({
        type: chart.type,
        innerSize:'50%',
        name:"site",
        data: series.data.map(data => ({
            name: data.name,
            y: data.y
        })),
      showInLegend: true,
    }));
  }
  else if(chart.type === "zoomType") { 
    seriesOptions = series.map((series: any) => ({
      type: series.type, 
      name: series.name,
      data: series.data,
    }));
  } else {
	seriesOptions =  series.map((seriesItem, index) => ({
		name: seriesItem.name,
		data: seriesItem.data,
		color: chart.isFragmentSizeRangeChart ? seriesItem.color : undefined
	}));
  }
  return seriesOptions;
}

function pushTrendline(seriesName: string, data: number[], trendlineSeriesOptions: any[], trendDashStyle: string,trendlineColor?: string) {
    const trendlineData = calculateTrendline(data);
    trendlineSeriesOptions.push({
        type: 'line',
        name: seriesName + ' Trendline',
        marker: {
            enabled: false
        },
        dashStyle: trendDashStyle,
        data: trendlineData,
        color: trendlineColor || null,
        zIndex: -1
    });
}

function calculateTrendline(data?: number[]): number[] {
  const n = data?.length;
  if (n === 0) return [];

  let sumX = 0;
  let sumY = 0;
  let sumXY = 0;
  let sumXSquare = 0;
  for (let i = 0; i < n; i++) {
      sumX += i;
      sumY += data[i];
      sumXY += i * data[i];
      sumXSquare += i * i;
  }

  const slope = (n * sumXY - sumX * sumY) / (n * sumXSquare - sumX * sumX);
  const intercept = (sumY - slope * sumX) / n;

  const trendlineData: number[] = [];
  for (let i = 0; i < n; i++) {
      trendlineData.push(slope * i + intercept);
  }

  return trendlineData;
}