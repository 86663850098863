import { Component, OnDestroy, OnInit } from '@angular/core';

import { ChartDataService } from '@app/backend/chart-data.service';
import { DrillAndBlastDataInterface, DrillDetailsInterface, FielderAnalyticsInterface }from "@app/models/chart-data.model";
import * as charts from '@utilities/highchart-objectify';

import * as Highcharts from 'highcharts/highstock';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-fielder-analytics',
  templateUrl: './fielder-analytics.component.html',
  styleUrls: ['./fielder-analytics.component.scss']
})
export class FielderAnalyticsComponent implements OnInit, OnDestroy {

  Highcharts: typeof Highcharts = Highcharts; // required

  chartOptions = []; // required
  subscriptions: Subscription = new Subscription();

  public loading:boolean;
  
  constructor(
    private chartDataService:ChartDataService) {
  }

  ngOnInit() {
    this.getReport();
    this.subscriptions.add(this.chartDataService.loading$.subscribe(loadingCount => {
      this.loading = !!loadingCount;
    }));
    this.subscriptions.add(this.chartDataService.fielderAnalytics$.subscribe(this.loadData))
  }

  async getReport() {
    this.subscriptions.add(this.chartDataService.getCacheFielderAnalytics().subscribe(this.loadData));
  }

  loadData = (chartData: FielderAnalyticsInterface)=> {
    this.chartOptions = charts.generateChartOptions(chartData?.fielderAnalyticsData);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
