import { Domain } from "@app/models/domain.model";
import { getPromise, graphqlPromise } from "./request-promise";
import { Subdomain } from "@app/models/subdomain.model";
import { Site } from "@app/models/site.model";
import { BehaviorSubject } from 'rxjs';

export interface UserDomainInfo {
	domains: Domain[],
	subdomains: Subdomain[],
}

export function getUserDomainInfo() {
  return getPromise('api/v1/users/get_user_domain_info',(data: UserDomainInfo)=> data ?? null);
}

export function getSubDomainSite(subDomainId: number) {
  return getPromise(`api/v1/users/${subDomainId}/getSitesFromSubdomain`,(data)=> data.sites ?? null);
}


export function fetchSiteDatasetsList(siteId:number){
	const query=`{
		datasets{
			id,
			name,
			is_phantom
		}
	}`;

	return graphqlPromise(null, query, { siteId }, data => {
		data=data?.data?.datasets;

		if (!data)
			return [];

		data = data.filter((d:any)=>d && !d.isPhantom);
		return data;
	});
}
