import { Pipe, PipeTransform } from '@angular/core';
import { UNIT_MAP } from './conversion-mapping.constant';

@Pipe({
  standalone: true,
  name: 'UnitConversionPipe',
  pure: false
})
export class UnitConversionPipe implements PipeTransform {
  transform(value: number, type: string = 'metric', key: string): number {
    if(typeof value != 'number') return value
    let convertedValue = value;
    const conversion = key + '_' + type
    switch(UNIT_MAP[conversion]) {
      case 'ft':
        convertedValue = value * 3.28084;
      break;

      case 'm':
        convertedValue = value * 0.3048;
      break;

      case 'inches': convertedValue = value * 0.0393701;
      break;
      case 'mm': convertedValue = value * 25.4;
      break;

      case 'm^3': convertedValue = value * 0.764555;
      break;

      case 'yard^3': convertedValue = value * 1.30795;
      break;

      case 'kg/m^3': convertedValue = value * 0.593276;
      break;

      case 'lb/yd^3': convertedValue = value * 1.68555;
      break;

      case 'kg/ metric ton': convertedValue = value * 0.00045;
      break;

      case 'lb/tn': convertedValue = value * 2204.622622;
      break;

    }
    return parseFloat(convertedValue?.toFixed(2));
  }
}
