import { Injectable } from '@angular/core';
import { DrillAndBlastParams } from '@app/models/chart-data.model';

@Injectable({
	providedIn: 'root'
})

export class GenerateChartDataService {
	
	public dataStructure = {
		pfAndTonData :{
			pfTonGraph: [
				{ dataField: 'powderFactorVolume', chartType: 'column', chartTitle: 'Powder Factor Volume by Shot' },
				{ dataField: 'totalVolume', chartType: 'line', chartTitle: 'Total Volume by Shot' },
			]
		},
		burdenAndSpacing: {
			burdenSpacinGraph: [
				{ dataField: 'averagePatternBurden', chartType: 'line', chartTitle: 'Average Burden by Shot' },
				{ dataField: 'averageSpacing', chartType: 'column', chartTitle: 'Average Spacing by Shot' },
			]
		},
		drillLengthAndDiameter: {
			drillDiameterGraph: [
				{ dataField: 'totalDrillLength', chartType: 'line', chartTitle: 'Total Drilling Length by Shot' },
				{ dataField: 'averageHoleDiameter', chartType: 'column', chartTitle: 'Average Hole Diameter by Shot' },
			]
		},
		holeCountAndLength: {
			holeCountGraph: [
				{ dataField: 'holeCount', chartType: 'column', chartTitle: 'Hole Count by Shot' },
				{ dataField: 'averageHoleLength', chartType: 'line', chartTitle: 'Average Hole Length' },
			],
		}
	};

	public originalDrillAndBalstData: DrillAndBlastParams[] =[];
	public filterdData:DrillAndBlastParams[] = [];
	public filteredData: DrillAndBlastParams[] = [];

	constructor(){
	}

	public getFilteredData(originalDrillAndBalstData: DrillAndBlastParams[], domainId: number, subdomainId: number, siteId: number) {
		if (!originalDrillAndBalstData || originalDrillAndBalstData.length === 0) {
			return [];
		}

		if (siteId) {
			return originalDrillAndBalstData.filter(data => data.siteId === siteId);
		} else if (subdomainId) {
			return originalDrillAndBalstData.filter(data => data.subdomainId === subdomainId);
		} else if (domainId) {
			return originalDrillAndBalstData.filter(data => data.domainId === domainId);
		} else {
			return originalDrillAndBalstData;
		}
	}

	public generateChartData(response) {
		let chartOptionsObject = {};
		Object.keys(this.dataStructure).forEach(sectionKey => {
		  chartOptionsObject[sectionKey] = {};
	
		  const section = this.dataStructure[sectionKey];
	
		  Object.keys(section).forEach(graphKey => {
			chartOptionsObject[sectionKey][graphKey] = [];
	
			const graphConfigurations = section[graphKey];
	
			graphConfigurations.forEach(config => {
			  const { dataField, chartType, chartTitle } = config;
			  if(response) {
				const chartOptions = this.getChartOptions(dataField, chartType, chartTitle, response);
				chartOptionsObject[sectionKey][graphKey].push(chartOptions);
			  }
			});
		  });
		});
	
		return chartOptionsObject;
	} 

	private getChartOptions(label1: string, chartType: string, yAxisLabel: string,response:any) {
		const datasetName = response?.map(cost => cost.datasetName);
		const original = response?.map(cost => Number(cost[label1]));

		const seriesData = [
			{ name: yAxisLabel , data: original },
		]

		return this.chartOptions(chartType, 'Shot', datasetName, yAxisLabel, seriesData);
	}

	private chartOptions(chartType: string, xAxisTitle: string, xAxisCategories: string[], yAxisTitle: string, seriesData: any) {
		const yAxisLabel = yAxisTitle.includes('Cost') ? `${yAxisTitle}($)`: yAxisTitle;
		const options:Highcharts.Options = {
			chart: {
				type: chartType,
				style: {
					fontFamily: 'Lato'
				},
			},
			title: {
				text: `${yAxisTitle} by ${xAxisTitle}`,
				align: 'left'
			},
			xAxis: {
				categories: xAxisCategories,
				title: {
					text: xAxisTitle,
					style: {
						fontSize: '1rem'
					}
				},
				labels: {
					style: {
						fontSize: '1rem'
					}
				}
			},
			yAxis: {
				title: {
					text: yAxisLabel,
					style: {
						fontSize: '1rem'
					}
				},
				labels: {
					style: {
						fontSize: '1rem'
					}
				}
			},
			credits: {
				enabled: false
			},
			series: seriesData
		}

		return options;
  	}
}