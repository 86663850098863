//decorator, used to mark simple type properties that should be stored in the storage object
type ClassType<T>={new(...args: any[]):T};
type PropType={new(...args: any[]):any}|'int'|'json'|'number';

class storePropertiesTable extends Map<string,PropType>{
};

const key_key='.storage-objectify-key';
const properties_key='.storage-objectify-properties';

interface Prototype{
	'.storage-objectify-properties'?:storePropertiesTable;
	'.storage-objectify-key'?:string;
	[key:string]:any;
}

export function store(type?:PropType, isKey?:'key'){
	return function(prototype:Prototype, propertyKey:string):void{
		if(!prototype[properties_key])
			prototype[properties_key]=new storePropertiesTable();
		prototype[properties_key].set(propertyKey,type);
		if(isKey)
			prototype[key_key]=propertyKey;
	};
}

function storeKey<T>(classType:ClassType<T>):string{
	let prototype=classType.prototype;
	if(!classType.prototype)
		console.error('no prototype',classType.name);
	let storeKey=prototype[key_key];
	if(!storeKey)
		throw 'no store key in '+classType.name;
	return storeKey;
}

function storeProperties(classType:object):storePropertiesTable{
	return classType[<keyof object>properties_key];
}

type NotAnArray={[key:string]:any,[i:number]:never};
export function from<T>(value:NotAnArray,classType:ClassType<T>):T;
export function from<T>(value:NotAnArray[],classType:ClassType<T>):T[];
export function from<T>(value:NotAnArray|NotAnArray[],classType:ClassType<T>):T|T[]{
	if(value instanceof Array){
		return value.map(item=>
			from(item,classType));
	}

	let instance=new classType;
	copy(instance,value);
	return instance;
}

export function copy(instance:Record<string,any>, object:Record<string|symbol,any>){
	if(typeof(instance)!=='object')
		throw 'instance must be an object';
	const prototype=Object.getPrototypeOf(instance);
	if(typeof(prototype)!=='object')
		throw 'instance must be a class instance';
	let properties=storeProperties(prototype);
	if(properties===undefined)
		throw 'table of @store() marked properties not found on class type "'+prototype.name+'"';

	let changed=false;
	properties.forEach((type,key)=>{
		let v=object[key];
		if(typeof(type)==='function'){
			if(v instanceof Array){
				if(!(instance[key] instanceof Map))
					instance[key]=new Map<any,any>();
				syncMap(instance[key],v,type);
			}else if(typeof(instance[key])==='object' && instance[key] instanceof type){
				if(copy(instance[key],v))
					changed=true;
			}else{
				instance[key]=new type();
				copy(instance[key],object);
				changed=true;
			}
		}else if(type==='json'){
			if(JSON.stringify(instance[key])!==v){
				instance[key]=JSON.parse(v);
				changed=true;
			}
		}else{
			if(type==='int' && (typeof(v)==='string'))
				v=parseInt(v);
			if(type==='number' && (typeof(v)==='string'))
				v=parseFloat(v);
			if(instance[key]!==v){
				instance[key]=v;
				changed=true;
			}
		}
	});
	return changed;
}

export function syncMap<Key,T>(map:Map<Key,T>, objects:Record<string|symbol,any>[], classType:ClassType<T>){
	let keyName=storeKey(classType);
	let properties=storeProperties(classType.prototype);
	let keyType=properties.get(keyName);

	let changed=false;
	let keysUnused=new Set<any>(map.keys());
	objects.forEach(object=>{
		let key:Key=object[keyName];
		if(keyType==='int' && typeof(key)==='string')
			key=<Key><unknown>parseInt(key);
		if(keyType==='number' && typeof(key)==='string')
			key=<Key><unknown>parseFloat(key);
		keysUnused.delete(key);
		// let classInstance=map.getOrAdd(key,()=>{
		// 	changed=true;
		// 	return new classType;
		// });
		// if(copy(classInstance,object))
		// 	changed=true;
	});
	keysUnused.forEach(key=>{
		changed=true;
		let v=<any>map.get(key);
		if(v.destroy)
			v.destroy();
		map.delete(key);
	});
	return changed;
}

export function to(value:any):object{
	if(value instanceof Array)
		return value.map(item=>to(item));

	if(value instanceof Object){
		const proto=Object.getPrototypeOf(value);
		if(proto && proto.constructor.name!=='Object'){
			let properties=storeProperties(proto);
			let object:any={};
			properties.forEach((type,key)=>{
				object[key]=value[key];
			});
			return object;
		}
	}
	return value;
}
