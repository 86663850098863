import { Component, OnDestroy, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import { ChartDataService } from '@app/backend/chart-data.service';
import { DrillAndBlastParams, DrillDetailsInterface }from "@app/models/chart-data.model";
import { GenerateChartDataService } from '@app/backend/generate-chart-data.service';
import { SiteService } from '@app/app-state/site.service';

import * as charts from '@utilities/highchart-objectify';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-pf-and-tons',
	templateUrl: './pf-and-tons.component.html',
	styleUrls: ['./pf-and-tons.component.scss']
})
export class PfAndTonsComponent implements OnInit, OnDestroy {

	Highcharts: typeof Highcharts = Highcharts; // required

	chartOptions = [] // required
	subscriptions: Subscription = new Subscription();

	public loading:boolean;

	private domainId: number;
	private subdomainId: number;
	private siteId: number;

	private pfAndTons:DrillAndBlastParams[] = [];

	constructor(
		private chartDataService:ChartDataService,
		private generateChartDataService:GenerateChartDataService,
		private siteService: SiteService ) {}

	ngOnInit(): void {
		this.getReport();
		this.subscriptions.add(this.chartDataService.loading$.subscribe(loadingCount => {
			this.loading = !!loadingCount;
		}));
		this.subscriptions.add(this.chartDataService.drillDetails$.subscribe(this.loadData));
		
		this.siteService.domainId$.subscribe(domainId => {
			this.domainId = domainId;
			this.triggerLoadData();
		});

		this.siteService.subDomainId$.subscribe(subdomainId => {
			this.subdomainId = subdomainId;
			this.triggerLoadData();
		});

		this.siteService.siteId$.subscribe(siteId => {
			this.siteId = siteId;
			this.triggerLoadData();
		});
	}

	async getReport() {
		this.subscriptions.add(this.chartDataService.getCacheDrillDetails().subscribe(this.loadData))
	}

	private loadData = (chartData: DrillAndBlastParams[])=> {
		this.pfAndTons = chartData;
		const data:DrillDetailsInterface = this.generateChartDataService.generateChartData(this.pfAndTons);
		for (const key in data?.pfAndTonData) {
			this.chartOptions = charts.generateChartOptions(data.pfAndTonData[key]);
		}
	}

	private triggerLoadData() {
		this.pfAndTons = this.generateChartDataService.getFilteredData(
			this.generateChartDataService.originalDrillAndBalstData,
			this.domainId,
			this.subdomainId,
			this.siteId
		);

		this.loadData(this.pfAndTons);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

}
