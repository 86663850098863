import { Component, OnDestroy, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import { ChartDataService } from '@app/backend/chart-data.service';
import { DrillAndBlastDataInterface, DrillDetailsInterface, DrillAndBlastParams }from "@app/models/chart-data.model";
import { GenerateChartDataService } from '@app/backend/generate-chart-data.service';

import * as charts from '@utilities/highchart-objectify';
import { Subscription } from 'rxjs';
import { SiteService } from '@app/app-state/site.service';

@Component({
	selector: 'app-drill-length-and-diameter',
	templateUrl: './drill-length-and-diameter.component.html',
	styleUrls: ['./drill-length-and-diameter.component.scss']
})
export class DrillLengthAndDiameterComponent implements OnInit, OnDestroy  {
	Highcharts: typeof Highcharts = Highcharts; // required

	chartOptions = [] // required
	subscriptions: Subscription = new Subscription();

	public loading:boolean;

	private domainId: number;
	private subdomainId: number;
	private siteId: number;

	private drillAndLength:DrillAndBlastParams[] = [];

	constructor(
		private chartDataService:ChartDataService,
		private siteService: SiteService,
		private generateChartDataService:GenerateChartDataService) {}

	ngOnInit(): void {
		this.getReport();
		this.subscriptions.add(this.chartDataService.loading$.subscribe(loadingCount => {
			this.loading = !!loadingCount;
		}));
		this.subscriptions.add(this.chartDataService.drillDetails$.subscribe(this.loadData));
		
		this.siteService.domainId$.subscribe(domainId => {
			this.domainId = domainId;
			this.triggerLoadData();
		});

		this.siteService.subDomainId$.subscribe(subdomainId => {
			this.subdomainId = subdomainId;
			this.triggerLoadData();
		});

		this.siteService.siteId$.subscribe(siteId => {
			this.siteId = siteId;
			this.triggerLoadData();
		});
	}

	private triggerLoadData() {
		this.drillAndLength = this.generateChartDataService.getFilteredData(
			this.generateChartDataService.originalDrillAndBalstData,
			this.domainId,
			this.subdomainId,
			this.siteId
		);

		this.loadData(this.drillAndLength);
	}

	async getReport() {
		this.subscriptions.add(this.chartDataService.getCacheDrillDetails().subscribe(this.loadData))
	}

	private loadData = (chartData: any)=> {
		this.drillAndLength = chartData;
		const data:DrillDetailsInterface = this.generateChartDataService.generateChartData(this.drillAndLength);
		for (const key in data?.drillLengthAndDiameter) {
			this.chartOptions = charts.generateChartOptions(data?.drillLengthAndDiameter[key]);
		}
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

}
