import { Component, OnDestroy, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import { ChartDataService } from '@app/backend/chart-data.service';
import { DrillDetailsInterface, DrillAndBlastParams }from "@app/models/chart-data.model";
import * as charts from '@utilities/highchart-objectify';
import { Subscription } from 'rxjs';
import { GenerateChartDataService } from '@app/backend/generate-chart-data.service';
import { SiteService } from '@app/app-state/site.service';

@Component({
	selector: 'app-hole-count-and-length',
	templateUrl: './hole-count-and-length.component.html',
	styleUrls: ['./hole-count-and-length.component.scss']
})
export class HoleCountAndLengthComponent implements OnInit, OnDestroy {

	Highcharts: typeof Highcharts = Highcharts; // required

	chartOptions = [] // required
	subscriptions: Subscription = new Subscription();

	public loading:boolean;

	private domainId: number;
	private subdomainId: number;
	private siteId: number;
	
	private holeAndCount:DrillAndBlastParams[] = [];

	constructor(
		private chartDataService:ChartDataService, 
		private generateChartDataService:GenerateChartDataService,
		private siteService: SiteService) {	}

	ngOnInit(): void {
		this.getReport();
		this.subscriptions.add(this.chartDataService.loading$.subscribe(loadingCount => {
			this.loading = !!loadingCount;
		}));
		this.subscriptions.add(this.chartDataService.drillDetails$.subscribe(this.loadData));

		this.siteService.domainId$.subscribe(domainId => {
			this.domainId = domainId;
			this.triggerLoadData();
		});

		this.siteService.subDomainId$.subscribe(subdomainId => {
			this.subdomainId = subdomainId;
			this.triggerLoadData();
		});

		this.siteService.siteId$.subscribe(siteId => {
			this.siteId = siteId;
			this.triggerLoadData();
		});
	}

	async getReport() {
		this.subscriptions.add(this.chartDataService.getCacheDrillDetails().subscribe(this.loadData))
	}

	private loadData = (chartData: DrillAndBlastParams[])=> {
		this.holeAndCount = chartData;
		const data:DrillDetailsInterface = this.generateChartDataService.generateChartData(this.holeAndCount);
		for (const key in data?.holeCountAndLength) {
			this.chartOptions = charts.generateChartOptions(data.holeCountAndLength[key]);
		}
	}

	private triggerLoadData() {
		this.holeAndCount = this.generateChartDataService.getFilteredData(
			this.generateChartDataService.originalDrillAndBalstData,
			this.domainId,
			this.subdomainId,
			this.siteId
		);

		this.loadData(this.holeAndCount);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

}
