import { Component, OnDestroy, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import { ChartDataService } from '@app/backend/chart-data.service';
import { DrillAndBlastDataInterface, DrillDetailsInterface, FielderAnalyticsInterface }from "@app/models/chart-data.model";

import * as charts from '@utilities/highchart-objectify';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-drilled-holes-by-drilled-length',
  templateUrl: './drilled-holes-by-drilled-length.component.html',
  styleUrls: ['./drilled-holes-by-drilled-length.component.scss']
})
export class DrilledHolesByDrilledLengthComponent implements OnInit, OnDestroy {

  Highcharts: typeof Highcharts = Highcharts; // required
  chartOptions = []; // required
  subscriptions: Subscription = new Subscription();

  public loading:boolean;
  
  constructor(
    private chartDataService:ChartDataService) {
  }

  ngOnInit() {
    this.getReport();
    this.subscriptions.add(this.chartDataService.loading$.subscribe(loadingCount => {
      this.loading = !!loadingCount;
    }));
    this.subscriptions.add(this.chartDataService.fielderAnalytics$.subscribe(this.loadData))
  }

  async getReport() {
    this.subscriptions.add(this.chartDataService.getCacheFielderAnalytics().subscribe(this.loadData))
  }

  loadData = (chartData: FielderAnalyticsInterface)=> {
    this.chartOptions = charts.generateChartOptions(chartData?.drilledHolesByDrilledLength);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
