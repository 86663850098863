import { getPromise, postPromise, putPromise } from "./request-promise";

import { BehaviorSubject } from 'rxjs';
import { DrillData, DrillShiftDetails } from '@app/models/data-collection.model';

export const dataCollection$ = new BehaviorSubject<DrillData[]>(null);
export const domainId$ = new BehaviorSubject<number>(null);


export function getDrillDataCollectionView(
	domainId: number,
	selectedTimeline: string,
	startDate: string,
	endDate: string,
	subDomainId:number,
	siteId: number,
	fileUpload:boolean = false,
) {
	const data = getCacheDataCollection();
	if (domainId) {
		const params =  `${domainId ? '&domain_id=' + domainId : ''}${selectedTimeline ? '&selected_timeline=' + selectedTimeline : ''}${startDate ? '&start_date=' + startDate : ''}${endDate ? '&end_date=' + endDate : ''}${'&file=' + fileUpload}${subDomainId ? '&subdomain_id=' + subDomainId : ''}${siteId ? '&site_id=' + siteId : ''}`
		return getPromise(`api/dashboards/get_drill_data_collection_view?=${params}`);
	}
	else
		return new Promise((resolve) => resolve(data));
}

export function updateDrillDataCollectionField(datasetId: number, key: string, value: string | number | Date) {
	return putPromise(
		`api/dashboards/update_drill_data_collection_field`,
		{ dataset_id: datasetId, key, value },
		(data) => data || null
	);
}

export function uploadFile(file: File) {
  const formData: FormData = new FormData();
  formData.append('file', file, file.name);

  return postPromise('/api/dashboards/upload_historical_drill_data_collection', formData);
}

export function addDataCollectionField(data, domainId) {
  return postPromise(
	`/api/dashboards/create_drill_data`,
	{ data, domain_id:domainId },
	(data) => data || null
  );
}

const getCacheDataCollection = ()=> dataCollection$.value as DrillData[];

export function getDrillShiftDetailsCollectionView(domainId: number, selectedTimeline:string, startDate:string, endDate: string, subDomainId:number,
	siteId: number, ) {
	const params =  `${domainId ? '&domain_id=' + domainId : ''}${selectedTimeline ? '&selected_timeline=' + selectedTimeline : ''}${startDate ? '&start_date=' + startDate : ''}${endDate ? '&end_date=' + endDate : ''}${subDomainId ? '&subdomain_id=' + subDomainId : ''}${siteId ? '&site_id=' + siteId : ''}`
	return getPromise(`api/dashboards/get_drill_shift_details_collection_view?=${params}`);
}

export function createDrillShiftDetails(drillShiftDetails:DrillShiftDetails) {
	return postPromise(
		'api/dashboards/create_drill_shift_detail',
		drillShiftDetails,
		(data: DrillShiftDetails) => data || null
	);
}

export function updateDrillShiftDetailsCollectionField(drillShiftDetailId: number, key: string, value: string | number | Date) {
	return putPromise(
		`api/dashboards/update_drill_shift_detail_collection_field`,
		{ id: drillShiftDetailId, key, value },
		(data) => data || null
	);
}


