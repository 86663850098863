import { Pipe, PipeTransform } from '@angular/core';
import { UNIT_MAP } from './conversion-mapping.constant';

@Pipe({
  standalone: true,
  name: 'UnitDisplayPipe'
})

export class UnitDisplayPipe implements PipeTransform {
  transform(value: string, type: string = 'metric'): string {
    const key =  value + '_' + type;
    const mapKey = UNIT_MAP[key] || UNIT_MAP[value]
    const val = mapKey ? ' (' + mapKey  + ')'  : ''
    return value + val;
  }
}
