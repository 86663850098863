export const UNIT_MAP = {
    "Total No. Of Boosters" : "no.",
    "Hole Diameter_imperial": 'inches',
    "Hole Diameter_metric": 'mm',
    "Hole Length_metric":'m',
    "Hole Length_imperial":'ft',
    "Total Drill Length_metric": 'm',
    "Total Drill Length_imperial": 'ft',
    "Total Drilling Depth_metric": 'm',
    "Total Drilling Depth_imperial": 'ft',
    "Bench Height_metric": 'm',
    "Bench Height_imperial": 'ft',
    "Pattern Burden_metric": 'm',
    "Pattern Burden_imperial": 'ft',
    "Spacing_metric": 'm',
    "Spacing_imperial": 'ft',
    "Subdrill_metric": 'm',
    "Subdrill_imperial": 'ft',
    "Total Volume_metric": 'm^3',
    "Total Volume_imperial": 'yard^3',
    "Powder Factor Volume_metric": 'kg/m^3',
    "Powder Factor Volume_imperial": 'lb/yd^3',
    "Powder Factor Weight_metric": 'kg/ metric ton',
    "Powder Factor Weight_imperial": 'lb/tn',
    "Total Drilled length_metric":'m',
    "Total Drilled length_imperial":'ft',
    "Tonnage": "tn",
    "Max No. Of Charges Per 8ms": "kg",
    "Total No. Of Detonators": "no.",
    "Total Designed Holes": "no.",
    "Total Drilled Holes": "no.",
    "Total Drilling Time": "hr",
    "Avg Time Between Holes": "min",
    "Avg Time Per Hole": "min",
    "Utilization": "%"
}
