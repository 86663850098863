import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatRow, MatTableDataSource } from '@angular/material/table';
import { SiteService } from '@app/app-state/site.service';
import { UserService } from '@app/app-state/user.service';
import { DrillData, DataSourceSchema, drillDataSourceCollectionSchema, mockDataCollection } from '@app/models/data-collection.model';
import { Subscription, combineLatest, debounceTime } from 'rxjs';
import * as backend from '@app/backend';
import * as XLSX from 'xlsx';
import { UnitDisplayPipe, UnitConversionPipe } from '@app/pipes';

@Component({
	selector: 'app-drill-data-collection-view',
	templateUrl: './drill-data-collection-view.component.html',
	styleUrls: ['./drill-data-collection-view.component.scss'],
})
export class DrillDataCollectionViewComponent implements OnInit, AfterViewInit, OnDestroy{
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChildren(MatRow, { read: ElementRef }) rows!: QueryList<ElementRef<HTMLTableRowElement>>;

    public measurementUnit: string = 'm';
	public isFileUploaded:boolean = false;
    public newRowData: DrillData;
    public isNewRow: boolean = false;
	public domainId:number = null;
	public datasetNames: { blast: string, apiV2DatasetId: number }[] = [];
	public selectedDataset: { blast: string, apiV2DatasetId: number };
	@ViewChild('fileInput') fileInput: ElementRef;

    private originalDataSourceCollectionResponse: DrillData[];
    private dataSourceCollectionResponse: DrillData[];
    public dataSourceSchema: DataSourceSchema[] = drillDataSourceCollectionSchema;
    public displayedColumns: string[] = this.dataSourceSchema.map(col => col.key);
    public dataSource: MatTableDataSource<DrillData>;

    public userUnitMeasurement: string;
    private userUnitMeasurementSubscription: Subscription;

	public loadingDrillData: boolean;
	selectedTimeline: string;
	startDate: string;
	endDate: string;
	subDomainId: number;
	siteId: number;

	public changeMeasurementArray = ['averageHoleDiameter','averageBenchHeight','averageHoleLength','totalDrillLength',
	'totalDrillDepth','averagePatternBurden','averageSpacing','averageSubdrill','shotVolume','powderFactorVolume','powderFactorWeight'];

    constructor(
        private snackBar: MatSnackBar,
        private readonly userService: UserService,
        private readonly siteService: SiteService
    ) {
			combineLatest([
				siteService.domainId$,
				siteService.selectedTimeline$,
				siteService.startDate$,
				siteService.endDate$,
				siteService.subDomainId$,
				siteService.siteId$
			]).pipe(
				debounceTime(500)).subscribe(ids => {
				const [domainId, selectedTimeline, startDate, endDate, subDomainId, siteId] = ids;
				this.domainId = domainId;
				this.selectedTimeline = selectedTimeline;
				this.startDate = startDate;
				this.endDate = endDate;
				this.subDomainId = subDomainId;
				this.siteId = siteId;

				this.fetchDrillDataCollectionView();
			});
    }

    ngOnInit(): void {
		this.displayedColumns.unshift(...['datasetName','blastReportUrl']);

		this.userUnitMeasurementSubscription = this.userService.userUnitMeasurement$.subscribe((measurement: string) => {
			this.userUnitMeasurement = measurement;
			this.convertMeasurements();
		});

		this.siteService.subDomainId$.subscribe(subdomainId => {
			if (subdomainId && this.originalDataSourceCollectionResponse) {
				const newCollection = this.originalDataSourceCollectionResponse.filter(data => data.subdomainId == subdomainId);
				this.dataSource = new MatTableDataSource(newCollection);
			}
		});
		this.siteService.siteId$.subscribe(siteId => {
			if (siteId && this.originalDataSourceCollectionResponse) {
				const newCollection = this.originalDataSourceCollectionResponse.filter(data => data.siteId == siteId);
				this.dataSource = new MatTableDataSource(newCollection);
			}
		});
    }

    ngAfterViewInit() {
		if (this.dataSource) {
			this.dataSource.paginator = this.paginator;
		}
    }

	ngOnDestroy(): void {
		this.userUnitMeasurementSubscription.unsubscribe();
	}

    applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
		this.dataSource.paginator = this.paginator;
		this.paginator.firstPage();
    }

    convertValue(value: number, conversion: string): number {
		let convertedValue = value;
		if (conversion === 'm2ft') {
			convertedValue = value * 3.28084;
		} else if (conversion === 'ft2m') {
			convertedValue = value * 0.3048;
		}

		return parseFloat(convertedValue.toFixed(2));
    }

    convertMeasurements(): void {
		const conversionType = this.userUnitMeasurement === 'imperial' ? 'm2ft' : 'ft2m';
		this.measurementUnit = conversionType;
    }

    private async fetchDrillDataCollectionView() {
		this.loadingDrillData = true;
		const response = await backend.getDrillDataCollectionView(this.domainId, this.selectedTimeline, this.startDate, this.endDate, this.subDomainId, this.siteId, this.isFileUploaded);
		backend.dataCollection$.next(response)

		if (response) {
			this.originalDataSourceCollectionResponse = response;
			this.dataSourceCollectionResponse = response.map((item: DrillData) => ({ ...item, isRecordExist: false }));
			this.datasetNames = response.map((res: DrillData) => ({ apiV2DatasetId: res.apiV2DatasetId, blast: res.blast }));
			this.dataSource = new MatTableDataSource(this.dataSourceCollectionResponse);

			if (!this.dataSource.paginator) {
				this.dataSource.paginator = this.paginator;
			}
		} else {
			this.dataSourceCollectionResponse = response;
		}
		this.loadingDrillData = false;
    }

	onFieldInputClick(event: Event, col: DataSourceSchema, element: DrillData): void {
		const target = event.target as HTMLInputElement;
		if (!element.apiV2DatasetId) {
		  this.newRowData = element;
		}
		target.readOnly = col.readonly && !!element.apiV2DatasetId;
	}

	async onFieldInputChange(element:DrillData, key: string, value: any, label)
	{
		if (!element.apiV2DatasetId) return
		if (key && value) {
			let tranformedValue: any = this.changeMeasurementArray.includes(key) ? parseFloat(value) : value;
			if(tranformedValue > 0 && this.userUnitMeasurement == "imperial"){
				tranformedValue = (new UnitConversionPipe()).transform(tranformedValue, 'metric', label)
			} else
				tranformedValue = value
			const response = await backend.updateDrillDataCollectionField(element.apiV2DatasetId, key, tranformedValue);
			if (response?.message) {
				this.snackBar.open(response.message, ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000});
			}
			element[key] = this.changeMeasurementArray.includes(key) ? parseFloat(tranformedValue) : tranformedValue;
		}
	}

	downloadToExcel(data: string) {
		const columnKeys = this.displayedColumns;
		const columnOrder = this.dataSourceSchema.map(item => item.label);
		columnOrder.unshift(...['Project', 'Blast Report URL']);
		const dataSource = data === "exportExcel" ? this.dataSource?.data  : mockDataCollection;

		if(!dataSource) return;
		const modifiedDataSource = dataSource.map(item => {
		return columnOrder.reduce((acc, header, index) => {
			acc[header] = item[columnKeys[index]];
			return acc;
		}, {});
		});

		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(modifiedDataSource);
		const wb: XLSX.WorkBook = XLSX.utils.book_new();

		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

		// Save to file
		const fileName = data === "exportData" ?  'SheetJS.xlsx' : 'Sample.xlsx';
		XLSX.writeFile(wb, fileName);
	}

	importAsExcel() {
		this.fileInput.nativeElement.click();
	  }

	  onFileChange(event: Event): void {
		const file = (event.target as HTMLInputElement).files[0];
		this.uploadFile(file);
	  }

	  async uploadFile(file: File) {
		const response = await backend.uploadFile(file)
		if(response) {
		  this.fileInput.nativeElement.value = null;

		  if (response?.message) {
			this.snackBar.open(response.message, ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000});
		  }

		  this.isFileUploaded = true;
		  this.fetchDrillDataCollectionView();
		}
		else {
			this.snackBar.open('Error importing data: Unsupported file format', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000});
		}
	  }

	  onSaveOrAddRow(): void {
		this.isNewRow ? this.saveRow() : this.addRow() ;
	  }

	  addRow(): void {
		this.isNewRow = true;
		let newRowData: any = {};
		this.dataSourceSchema.forEach((col) => {
			newRowData[col.key] = '';
		});
		this.paginator.firstPage();

		const currentData = this.dataSource.data.slice();
		currentData.splice(0, 0, newRowData);

		this.newRowData = newRowData;
		this.dataSource.data = currentData;
		this.scrollToFirstIndex();
	}

	scrollToFirstIndex(): void {
		let elem = this.rows.first;
		elem?.nativeElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
	}

	  removeRow(): void {
		this.isNewRow = false;
		const index = this.dataSource.data.findIndex(
		  (row) => row === this.newRowData
		);
		if (index !== -1) {
		  this.dataSource.data.splice(index, 1);
		  this.dataSource._updateChangeSubscription();
		}
	  }

	  async saveRow() {
		if (this.newRowData) {
			this.isNewRow = false;
			this.newRowData.blast = this.selectedDataset.blast;
			this.newRowData.apiV2DatasetId = this.selectedDataset.apiV2DatasetId;

			const response = await backend.addDataCollectionField(this.newRowData, this.domainId);
			if (response?.message) {
				this.snackBar.open(response.message, '', {
					horizontalPosition: 'right',
					verticalPosition: 'top',
					duration: 3 * 1000,
				});
				this.newRowData.isRecordExist = true;
				this.selectedDataset = null;
			}
		}
	  }

}
