import { store } from '@utilities/storage-objectify';

export class User{
	@store() address:string;
	@store() authenticationToken:string;
	@store() avatarUrl:string;
	@store() companyAddress:string;
	@store() companyName:string;
	@store() email:string;
	@store() firstName:string;
	@store('int') id:number;
	@store() industry:string;
	@store() lastName:string;
	@store() measurementUnit:'imperial'|'metric';
	@store() name:string;
	@store() phone:string;
	@store() isGuest:boolean=false;
	@store() blasterSignatureUrl:string;
	@store() receiveProjectEmails:boolean;
	@store() monthlyProjectLimit:number;
	@store() isTrialUser:boolean;
	@store() roundOff:boolean=false;
	@store() designerLogoUrl:string;
	@store() customerLogoUrl:string;
	@store() designerName:string;
	@store() customerName:string;

	public isSuperAdmin(){
		return this.email.endsWith('@strayos.com');
	}
}