<div class="content-container">
    <div class="header-container">
        <mat-form-field appearance="outline">
            <mat-label>Search</mat-label>
            <input matInput #searchInput (keyup)="applyFilter(searchInput.value)">
        </mat-form-field>

        <div class="actions-container">
            <button mat-raised-button color="primary" (click)="importAsExcel()">Import as Excel</button>
            <input  type="file" #fileInput style="display: none" (change)="onFileChange($event)" />

            <button mat-raised-button class="export-button primary" color="primary" (click)="downloadToExcel('exportExcel')">Export as Excel</button>
            <button mat-raised-button class="primary" color="primary" (click)="downloadToExcel('downloadSample')">Download Sample</button>

        </div>
    </div>

    <div class="table-container">
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="datasetName">
                <th mat-header-cell *matHeaderCellDef>Project</th>
                <td mat-cell *matCellDef="let element" #cell>
                    <mat-select  *ngIf="!element.isRecordExist && !element.apiV2DatasetId" [(ngModel)]="selectedDataset">
                        <mat-option *ngFor="let dataset of datasetNames" [value]="dataset">{{ dataset.blast }}</mat-option>
                    </mat-select>
                    <span class="xs-display" *ngIf="element.isRecordExist || element.apiV2DatasetId">{{element.blast}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="blastReportUrl">
                <th mat-header-cell *matHeaderCellDef>Blast Report URL</th>
                <td mat-cell *matCellDef="let element">
                    <input type="text" matInput [(ngModel)]="element.blastReportUrl" *ngIf="!element.isRecordExist && !element.apiV2DatasetId" />
                    <a class="xs-display" [href]="element.blastReportUrl" target="_blank" *ngIf="element.isRecordExist || element.apiV2DatasetId">{{element.blastReportUrl}}</a>
                </td>
            </ng-container>

            <ng-container [matColumnDef]="col.key" *ngFor="let col of dataSourceSchema">
                <th mat-header-cell *matHeaderCellDef>
                    {{ col.label | UnitDisplayPipe:userUnitMeasurement }}
                </th>

                <td mat-cell *matCellDef="let element">
                    <input [type]="col.type" matInput [ngModel]="element[col.key] | UnitConversionPipe:userUnitMeasurement:col.label"
                        *ngIf="changeMeasurementArray.includes(col.key) && userUnitMeasurement === 'imperial'"
                        readonly="true"
                        (focus)="onFieldInputClick($event, col, element)"
                        [required]="col.required"
                        (ngModelChange)="onFieldInputChange(element, col.key, $event, col.label);"
                    />

                    <input [type]="col.type" matInput [ngModel]="element[col.key]"
                        *ngIf="!changeMeasurementArray.includes(col.key) || userUnitMeasurement !== 'imperial'"
                        readonly="true"
                        (focus)="onFieldInputClick($event, col, element)"
                        (ngModelChange)="onFieldInputChange(element, col.key, $event, col.label);"
                    />
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator
            pageSize="30"
            [pageSizeOptions]="[30, 40]"
            hidePageSize="true"
        >
        </mat-paginator>

        <div class="actions-container">
            <button
                *ngIf="domainId"
                mat-raised-button
                color="primary"
                (click)="onSaveOrAddRow()"
            >
                {{ isNewRow ? "Save" : "Add Row" }}
            </button>
            <button
                mat-raised-button
                color="primary"
                *ngIf="isNewRow"
                (click)="removeRow()"
            >
                Remove
            </button>
        </div>

    </div>
</div>

<div class="loading-container" *ngIf="loadingDrillData">
    <mat-spinner matTooltip="Loading Drill Data Collection View"></mat-spinner>
</div>
