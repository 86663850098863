<mat-tab-group (selectedTabIndexChange)="tabChange($event)">
  <mat-tab label="Hole Count & Length" >
    <ng-template matTabContent>
      <app-hole-count-and-length></app-hole-count-and-length>
    </ng-template>
  </mat-tab>
  <mat-tab label="Drill Length & Diameter">
    <ng-template matTabContent>
      <app-drill-length-and-diameter></app-drill-length-and-diameter>
    </ng-template>
  </mat-tab>
  <mat-tab label="Burden & Spacing">
    <ng-template matTabContent>
      <app-burden-and-spacing></app-burden-and-spacing>
    </ng-template>
  </mat-tab>
  <mat-tab label="PF & Tons">
    <ng-template matTabContent>
      <app-pf-and-tons></app-pf-and-tons>
    </ng-template>
  </mat-tab>
</mat-tab-group>
