<mat-tab-group headerPosition="below" [(selectedIndex)]="selectedMatTabIndex" (selectedTabChange)="onSelectedMatTabChange($event)">
    <mat-tab label="Drill & Blast Costs Chart View">
        <div class="content-container">
            <div *ngIf="drillAndBlastCosts?.length === 0" class="no-data">
                <span>No Drill & Blast Cost found for this selection. </span>
                <button mat-raised-button color="primary" (click)="initiateDrillAndBlastCosts()">Click</button> to add
            </div>

            <div class="graphs-container">
                <div class="graph" #tonsPerHourGraph></div>

                <div class="graph" #finesPercentGraph></div>

                <div class="graph" #oversizePercentGraph></div>

                <div class="graph" #explosivesCostPerTonGraph></div>

                <div class="graph" #drillingCostPerTonGraph></div>

                <div class="graph" #drillAndBlastCostPerTonGraph></div>

                <div class="graph" #secondaryBreakageCostGraph></div>
            </div>
        </div>
    </mat-tab>

    <mat-tab label="Drill & Blast Costs Table View">
        <div class="header-container">
            <button mat-raised-button color="primary" (click)="initiateDrillAndBlastCosts()">+ Drill & Blast Cost</button>

            <button *ngIf="currentDrillAndBlastCost && !currentDrillAndBlastCost.id" mat-raised-button color="primary" (click)="addDrillAndBlastCosts()">Submit</button>
        </div>


        <div class="content-container">
            <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="datasetName">
                    <th mat-header-cell *matHeaderCellDef>Project</th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="!element.id; else displayColumn">
                            <mat-select (selectionChange)="onDatasetSelect($event)" class="select-project">
                                <mat-option *ngFor="let dataset of siteDatasetLists" [value]="dataset">{{dataset.name}}</mat-option>
                            </mat-select>
                        </ng-container>

                        <ng-template #displayColumn>
                            <span class="select-project">{{element.datasetName}}</span>
                        </ng-template>
                    </td>
                </ng-container>

                <ng-container [matColumnDef]="col.key" *ngFor="let col of dataSourceSchema">
                    <th mat-header-cell *matHeaderCellDef>
                        {{col.label}}
                    </th>

                    <td mat-cell *matCellDef="let element">
                        <input [type]="col.type" matInput [(ngModel)]="element[col.key]" readonly="true" (click)="onFieldInputClick($event)" (ngModelChange)="onFieldInputChange(element, col.key, $event)" />
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator
                pageSize="30"
                [pageSizeOptions]="[30, 40]"
                hidePageSize="true"
            >
            </mat-paginator>
        </div>
    </mat-tab>
</mat-tab-group>

<div class="loading-container" *ngIf="loadingDrillAndBlastCosts">
    <mat-spinner></mat-spinner>
</div>