import { Component } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@app/app-state/user.service';
import { Subscription } from 'rxjs'

@Component({
	selector: 'unit-settings-dialog',
	templateUrl: './unit-settings-dialog.component.html',
	styleUrls: ['./unit-settings-dialog.component.scss'],
})

export class UnitSettingsDialog{
	public static readonly config:MatDialogConfig={
		disableClose: true,
	};

  private userSubscription: Subscription;
	public constructor(
		private dialog:MatDialogRef<UnitSettingsDialog>,
		public readonly userService:UserService,
	){}

	public roundDrillDesign$= this.userService.user.roundOff;
	public userUnitMeasurement = this.userService.user.measurementUnit;

	public saveMeasurementSettings(){
		this.userService.user.measurementUnit = this.userUnitMeasurement;
		this.userService.user.roundOff = this.roundDrillDesign$;
		if(this.userService.user.isGuest){
			// this.userService.user.setUnits();
		}
		else {
			this.userService.saveUser();
		}
		this.dialog.close();
	}
}
