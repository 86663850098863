import { Injectable } from '@angular/core';

import { getPromise } from "./request-promise";
import { BehaviorSubject, of, switchMap, take, combineLatest, debounceTime, distinctUntilChanged } from 'rxjs';

import { SiteService } from '../app-state/site.service';
import { UserService } from '@app/app-state/user.service';

import { DrillAndBlastDataInterface, DrillAndBlastParams, DrillDetailsInterface, FielderAnalyticsInterface, FielderDataset }from "@app/models/chart-data.model";
import { GenerateChartDataService } from './generate-chart-data.service';

@Injectable({
	providedIn: 'root'
})

export class ChartDataService {

	readonly drillDetails$ = new BehaviorSubject<DrillAndBlastParams[]>(null);
	readonly fragmentationData$ = new BehaviorSubject<DrillAndBlastDataInterface[]>(null);
	readonly fielderAnalytics$ = new BehaviorSubject<FielderAnalyticsInterface>(null);
	readonly fielderAnalyticsByDrill$ = new BehaviorSubject<FielderAnalyticsInterface>(null);
	readonly allFielderDataset$ = new BehaviorSubject<FielderDataset[]>(null)
	private loadingSubject = new BehaviorSubject<number>(0);
	public loading$ = this.loadingSubject.asObservable();
	public userUnitMeasurement: string;
	
	public get allFielderDataset() {
		return this.allFielderDataset$.value;
	};

	domainId;
	selectedTimeline;
	startDate;
	endDate;
	selectedDrillMachine;
	loadingData = false;

	constructor(private siteService: SiteService, private userService: UserService, private generateChartDataService:GenerateChartDataService){
		combineLatest([
			siteService.domainId$,
			siteService.selectedTimeline$, siteService.startDate$, siteService.endDate$,
			siteService.selectedDrillMachine$,
			userService.userUnitMeasurement$
		]).pipe((
			debounceTime(300),
			distinctUntilChanged()
		))
		.subscribe(([domainId, selectedTimeline, startDate, endDate, selectedDrillMachine,userUnitMeasurement]) => {
			this.domainId = domainId;
			this.selectedTimeline = selectedTimeline;
			this.startDate = startDate;
			this.endDate = endDate;
			this.selectedDrillMachine = selectedDrillMachine;
			this.userUnitMeasurement = userUnitMeasurement;
			this.resetAllValues();
		});
	}

	resetAllValues() {
		this.drillDetails$.next(null);
		this.fragmentationData$.next(null);
		this.fielderAnalytics$.next(null);
		this.fielderAnalyticsByDrill$.next(null);
		if(this.domainId) {
			this.loadingSubject.next(this.loadingSubject.value + 1);
			this.loadingData = true;
			this.updateData();
		}
	}

	updateData() {
		switch(this.siteService.activeSideNav) {
			case 'drillAndBlast':
			this.getAllDrillDetails();
			break;

			case 'fragmentation':
			this.getAllFragmentationData()
			break;

			case 'fielder':
			this.getAllFielderAnalytics()
			this.getAllFielderAnalyticsByDrill()
			break;
		}
	}

	getAllDrillDetails() {
		return getPromise(
			`api/dashboards/highchart_analytics/drill_details?${this.apiParams()}`,
			(data) => data.data
		).then((details) => {
			this.generateChartDataService.originalDrillAndBalstData = details;
			this.drillDetails$.next(details);
			this.loadingData = false;
			return details
		}).finally(() => this.handleLoading());
	}

	getAllFragmentationData() {
		return getPromise(
			`/api/dashboards/highchart_analytics/fragmentation_chart_data?${this.apiParams()}`,
		(data) => data.data.fragmentationGraph).then((details) => {
			this.fragmentationData$.next(details)
			this.loadingData = false;
			return details
		}).finally(() => this.handleLoading());
	}

	getAllFielderAnalytics() {
		return getPromise(
			`/api/dashboards/highchart_analytics/fielder_analytics_data?${this.apiParams()}`,
		(data) => data.data).then((details) => {
			this.fielderAnalytics$.next(details)
			this.loadingData = false;
			return details
		}).finally(() => this.handleLoading());
	}

	getAllFielderAnalyticsByDrill() {
		return getPromise(
			`/api/dashboards/highchart_analytics/fielder_analytics_drill_data?${this.apiParams()}`,
		(data) => data.data).then((details) => {
			this.fielderAnalyticsByDrill$.next(details)
			this.loadingData = false;
			return details
		}).finally(() => this.handleLoading());
	}

	getDrillMachines() {
		return getPromise(
			`/api/dashboards/highchart_analytics/fetch_drill_machines?${this.apiParams()}`,
		(data) => data.data).then((details) => {
			this.allFielderDataset$.next(details)
			this.loadingData = false;
			return details;
		}).finally(() => this.handleLoading());
	}

	getCacheDrillDetails() {
		return this.drillDetails$.pipe(
			take(1),
			switchMap((details) => {
			if (details === null && !this.loadingData) {
				return this.getAllDrillDetails();
			}
			return of(details);
			}),
		);
	}

	getCacheFragmentationData() {
		return this.fragmentationData$.pipe(
			take(1),
			switchMap((details) => {
			if (details === null && !this.loadingData) {
				return this.getAllFragmentationData();
			}
			return of(details);
			}),
		);
	}

	getCacheFielderAnalytics() {
		return this.fielderAnalytics$.pipe(
			take(1),
			switchMap((details) => {
			if (details === null && !this.loadingData) {
				return this.getAllFielderAnalytics();
			}
			return of(details);
			}),
		);
	}

	getCacheFielderAnalyticsByDrill() {
		return this.fielderAnalyticsByDrill$.pipe(
			take(1),
			switchMap((details) => {
			if (details === null && !this.loadingData) {
				return this.getAllFielderAnalyticsByDrill();
			}
			return of(details);
			}),
		);
	}

	apiParams(){
		return `${this.domainId ? '&domain_id=' + this.domainId : ''}${this.selectedTimeline ? '&selected_timeline=' + this.selectedTimeline : ''}${this.startDate ? '&start_date=' + this.startDate : ''}${this.endDate ? '&end_date=' + this.endDate : ''}${this.selectedDrillMachine ? '&uuid=' + this.selectedDrillMachine : ''}`
	}

	handleLoading() {
		this.loadingSubject.next(Math.max(0, this.loadingSubject.value - 1));
	}
}


