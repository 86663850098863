import { Injectable, NgZone } from '@angular/core';
import { User } from '@app/models/user.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { storageLocal } from "@utilities/storage-local";
import * as storageObjectify from "@utilities/storage-objectify";
import * as backend from '@app/backend';
import { environment } from '@environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	[x: string]: any;
	public readonly user$ = new BehaviorSubject<User>(null);
 	private userUnitMeasurementSubject = new BehaviorSubject<string>('metric');
  	userUnitMeasurement$: Observable<string> = this.userUnitMeasurementSubject.asObservable();

	public get user() {
		return this.user$.value;
	};

	public readonly userDomainInfo$ = new BehaviorSubject<backend.UserDomainInfo>(null);

	public get userDomainInfo() {
		return this.userDomainInfo$.value;
	};

	constructor(
		private readonly ngZone: NgZone,
		private snackBar: MatSnackBar
	) { }

	public async login(credentials: { email: string; password: string }) {
		const response = await backend.postPromise("api/v1/sessions", credentials);

		if (response) {
			const user = storageObjectify.from(response, User);
			localStorage.setItem("email", user.email);
			localStorage.setItem("authenticationToken", user.authenticationToken);

			const hasValidLicense = await this.getStrayosDashboardLicense(user);
			if (!hasValidLicense) {
				this.snackBar.open('User License is either expired or not found', ' ', { horizontalPosition: 'right', verticalPosition: 'top' });
				return null;
			}

			if (hasValidLicense) {
				this.getUserDomainInfo();
			}

			return user;
		} else {
			this.snackBar.open('Login Failed', ' ', { horizontalPosition: 'right', verticalPosition: 'top' });
		}

		return null;
	}

	public async loggedIn() {
		if (this.user)
			return true;

		return await this.loginFromStorage();
	}

	private loginFromStorage() {
		return new Promise<boolean>(async (resolve) => {
			const email = localStorage.getItem("email");
			const token = localStorage.getItem("authenticationToken");

			if (!email || !token)
				return resolve(false);

			const response = await backend.getPromise('api/v1/users/me');

			if (response) {
				const user = storageObjectify.from(response, User);
				this.user$.next(user);
				this.userUnitMeasurementSubject.next(user.measurementUnit);

				localStorage.setItem("email", user.email);
				localStorage.setItem("authenticationToken", user.authenticationToken);

				const hasValidLicense = await this.getStrayosDashboardLicense(user);
				if (!hasValidLicense) {
					this.snackBar.open('User License is either expired or not found', ' ', { horizontalPosition: 'right', verticalPosition: 'top' });
					resolve(false);
				}

				if (hasValidLicense) {
					this.getUserDomainInfo();
				} else {
					resolve(false);
				}

				resolve(true);
			} else {
				resolve(false);
			}

		});
	}

	public async logout() {
		await backend.deletePromise('api/v1/sessions');
		this.user$.next(null);
		localStorage.clear();

		// this.ngZone.runOutsideAngular(()=>{
		// 	const iframe = document.getElementById('strayos-login-iframe') as HTMLIFrameElement;
		// 	iframe.contentWindow.postMessage('strayosLogout', environment.accountsLoginUrl);
		// });

		window.location.href = '/';
	}

	private async getUserDomainInfo() {
		const userDomainInfo = await backend.getUserDomainInfo();

		if (userDomainInfo) {
			this.userDomainInfo$.next(userDomainInfo);
		}
	}

	public async getStrayosDashboardLicense(user: User) {
		const response = await backend.getStrayosDashboardLicense(user.id);

		if (response.isValidLicense) {
			return true;
		}

		return false;
	}

	public saveUser(success?: (message:string) => void, failure?: (message:string) => void) {
		let user = this.user;

		let updatedUser = {
			address: user.address,
			authenticationToken: user.authenticationToken,
			avatar_url: user.avatarUrl,
			company_address: user.companyAddress,
			company_name: user.companyName,
			email: user.email,
			first_name: user.firstName,
			id: user.id,
			industry: user.industry,
			last_name: user.lastName,
			measurement_unit: user.measurementUnit,
			name: user.name,
			phone: user.phone,
			blaster_signature_url: user.blasterSignatureUrl,
			receive_project_emails: user.receiveProjectEmails,
			round_off: user.roundOff,
			designer_name: user.designerName,
			designer_logo_url: user.designerLogoUrl,
			customer_name: user.customerName,
			customer_logo_url: user.customerLogoUrl
		};
		// user.setUnits();
		backend.putPromise(`api/v1/users/${updatedUser.id}`, updatedUser, () => {
			// user.setUnits();
			this.userUnitMeasurementSubject.next(updatedUser.measurement_unit);
			if(success)
				console.log('User Updated Successfully.');
		});0
	}


	public setUnits() {
		// const units=<Record<measurement.Type,measurement.Unit>>{};
		// if (this.measurementUnit==='imperial') {
		// 	for(const type of measurement.types)
		// 		this.unitsIndividual$.get(type).nextIfChanged(measurement.typeMeta[type].imperialUnit);
		// 	for(const type of measurement.types)
		// 		units[type]=measurement.typeMeta[type].imperialUnit;
		// } else {
		// 	for(const type of measurement.types)
		// 		this.unitsIndividual$.get(type).nextIfChanged(measurement.typeMeta[type].metricUnit);
		// 	for(const type of measurement.types)
		// 		units[type]=measurement.typeMeta[type].metricUnit;
		// }
		// const preferences=this.measurementTypePreferences$.value;
		// for(const preferenceType in preferences) {
		// 	const type = preferenceType as keyof MeasurementTypesMap;
		// 	if(preferences[type]) {
		// 		this.unitsIndividual$.get(type).nextIfChanged(preferences[type]);
		// 		units[type]=preferences[type]
		// 	}
		// }
		// this.units$.next(units);
	}

}
