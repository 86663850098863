import { Injectable } from '@angular/core';
import { DrillAndBlastCostsParams } from '@app/models/drill-and-blast-costs.model';
import * as Highcharts from 'highcharts/highstock';

@Injectable({
	providedIn: 'root'
})

export class DrillAndBlastCostService {
	public originalDrillAndBlastCosts: DrillAndBlastCostsParams[] = [];
	private filteredDrillAndBlastCosts: DrillAndBlastCostsParams[] = [];

	private siteNameAsXAxisLabels: string[] = [];

	public tonsPerHourChartOptions: Highcharts.Options;
	public finesPercentChartOptions: Highcharts.Options;
	public oversizePercentChartOptions: Highcharts.Options;
	public explosivesCostPerTonChartOptions: Highcharts.Options;
	public drillingCostPerTonChartOptions: Highcharts.Options;
	public drillAndBlastCostPerTonChartOptions: Highcharts.Options;
	public secondaryBreakageCostChartOptions: Highcharts.Options;

	constructor(
	) { }

	public constructDrillAndBlastCostCharts(drillAndBlastCosts: DrillAndBlastCostsParams[]) {
		this.filteredDrillAndBlastCosts = drillAndBlastCosts;

		this.siteNameAsXAxisLabels = this.filteredDrillAndBlastCosts.map(cost => cost.datasetName);

		this.tonsPerHourChartOptions = this.getChartOptions('tonsPerHour', 'tonsPerHourGoal', 'bar', 'Tons per Hour');
		this.finesPercentChartOptions = this.getChartOptions('finesPercent', 'finesPercentGoal', 'area', 'Fines Percent');
		this.oversizePercentChartOptions = this.getChartOptions('oversizePercent', 'oversizePercentGoal', 'area', 'Oversize Percent');
		this.explosivesCostPerTonChartOptions = this.getChartOptions('explosivesCostPerTon', 'explosivesCostPerTonGoal', 'column', 'Explosives Cost Per Ton');
		this.drillingCostPerTonChartOptions = this.getChartOptions('drillingCostPerTon', 'drillingCostPerTonGoal', 'bar', 'Drilling Cost Per Ton');
		this.drillAndBlastCostPerTonChartOptions = this.getChartOptions('drillAndBlastCostPerTon', 'drillAndBlastCostPerTonGoal', 'column', 'Drill And Blast Cost Per Ton');
		this.secondaryBreakageCostChartOptions = this.getChartOptions('secondaryBreakageCost', 'secondaryBreakageCostGoal', 'area', 'Secondary Breakage Cost');
	}


	private getChartOptions(label1: string, label2: string, chartType: string, yAxisLabel: string) {
		const original = this.filteredDrillAndBlastCosts.map(cost => Number(cost[label1]));
		const goals = this.filteredDrillAndBlastCosts.map(cost => Number(cost[label2]));

		const seriesData = [
			{ name: yAxisLabel , data: original },
			{ name: `${yAxisLabel} Goal`, data: goals }
		]

		return this.chartOptions(chartType, 'Sites', this.siteNameAsXAxisLabels, yAxisLabel, seriesData);
	}

	private chartOptions(chartType: string, xAxisTitle: string, xAxisCategories: string[], yAxisTitle: string, seriesData: any) {
		const yAxisLabel = yAxisTitle.includes('Cost') ? `${yAxisTitle}($)`: yAxisTitle;
		const options:Highcharts.Options = {
			chart: {
				type: chartType,
				style: {
					fontFamily: 'Lato'
				},
			},
			title: {
				text: `${yAxisTitle} by ${xAxisTitle}`,
				align: 'left'
			},
			xAxis: {
				categories: xAxisCategories,
				title: {
					text: xAxisTitle,
					style: {
						fontSize: '1rem'
					}
				},
				labels: {
					style: {
						fontSize: '1rem'
					}
				}
			},
			yAxis: {
				title: {
					text: yAxisLabel,
					style: {
						fontSize: '1rem'
					}
				},
				labels: {
					style: {
						fontSize: '1rem'
					}
				}
			},
			credits: {
				enabled: false
			},
			series: seriesData
		}

		return options;
  	}
}

