import { Component, OnDestroy, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import { ChartDataService } from '@app/backend/chart-data.service';
import { FielderAnalyticsInterface }from "@app/models/chart-data.model";

import * as charts from '@utilities/highchart-objectify';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-utilization-on-shot',
  templateUrl: './utilization-on-shot.component.html',
  styleUrls: ['./utilization-on-shot.component.scss']
})
export class UtilizationOnShotComponent implements OnInit, OnDestroy{

  Highcharts: typeof Highcharts = Highcharts; // required

  chartOptions = [];
  subscriptions: Subscription = new Subscription();

  public loading:boolean;
  
  constructor(private chartDataService:ChartDataService) {}

  ngOnInit(): void {
    this.getReport();
    this.subscriptions.add(this.chartDataService.loading$.subscribe(loadingCount => {
      this.loading = !!loadingCount;
    }));
  }

  async getReport() {
    this.subscriptions = this.chartDataService.getCacheFielderAnalytics().subscribe((chartData: FielderAnalyticsInterface)=> {
      this.chartOptions = charts.generateChartOptions(chartData.utilizationByShot);
    })
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
