import { DrillAndBlastCostsParams } from "@app/models/drill-and-blast-costs.model";
import { getPromise, postPromise, putPromise } from "./request-promise";

export function getDrillAndBlastCosts(siteId: number) {
	return getPromise(
		`drill_and_blast_costs?api_v2_site_id=${siteId}`,
		(data: DrillAndBlastCostsParams) => data || null
	);
}

export function getDrillAndBlastCostsByDomain(domainId: number) {
	return getPromise(
		`drill_and_blast_costs/get_drill_and_blast_costs_by_domain?domain_id=${domainId}`,
		(data: DrillAndBlastCostsParams[]) => data || null
	);
}

export function createDrillAndBlastCosts(drillAndBlastCostsParams:DrillAndBlastCostsParams) {
	return postPromise(
		'drill_and_blast_costs',
		drillAndBlastCostsParams,
		(data: DrillAndBlastCostsParams) => data || null
	);
}

export function updateDrillAndBlastCosts(drillAndBlastCostsParams:DrillAndBlastCostsParams) {
	return putPromise(
		`drill_and_blast_costs/${drillAndBlastCostsParams.id}`,
		drillAndBlastCostsParams,
		(data: DrillAndBlastCostsParams) => data || null
	);
}