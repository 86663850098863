import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SiteService {
  public readonly siteId$ = new BehaviorSubject<number>(null);
  public readonly domainId$ = new BehaviorSubject<number>(null);
  public readonly subDomainId$ = new BehaviorSubject<number>(null);
  public readonly selectedTimeline$ = new BehaviorSubject<string>('');
  public readonly startDate$ = new BehaviorSubject<string>('');
  public readonly endDate$ = new BehaviorSubject<string>('');
  public readonly selectedDrillMachine$ = new BehaviorSubject<string>('');
  public readonly activeSideNav$ = new BehaviorSubject<string>('drillAndBlast');
  public readonly activeDrillTab$ = new BehaviorSubject<number>(0);

  constructor() { }

  public get siteId() {
    return this.siteId$.value;
  }

  public get domainId() {
    return this.domainId$.value;
  }

  public get subDomainId() {
    return this.subDomainId$.value;
  }

  public get activeSideNav() {
    return this.activeSideNav$.value;
  }

  public get activeDrillTab() {
    return this.activeDrillTab$.value;
  }
  
}
