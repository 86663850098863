import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import { ChartDataService } from '@app/backend/chart-data.service';
import { FielderAnalyticsInterface }from "@app/models/chart-data.model";

import * as charts from '@utilities/highchart-objectify';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-fielder-analytics-by-drill',
  templateUrl: './fielder-analytics-by-drill.component.html',
  styleUrls: ['./fielder-analytics-by-drill.component.scss']
})
export class FielderAnalyticsByDrillComponent implements OnInit {

  Highcharts: typeof Highcharts = Highcharts; // required

  chartOptions = [];
  subscriptions: Subscription = new Subscription();

  public loading:boolean;
  
  constructor(private chartDataService:ChartDataService) {}

  ngOnInit() {
    this.getReport();
    this.subscriptions.add(this.chartDataService.loading$.subscribe(loadingCount => {
      this.loading = !!loadingCount;
    }));
    this.subscriptions.add(this.chartDataService.fielderAnalyticsByDrill$.subscribe(this.loadData))
  }

  async getReport() {
    this.subscriptions.add(this.chartDataService.getCacheFielderAnalyticsByDrill().subscribe(this.loadData));
  }

  loadData = (chartData: FielderAnalyticsInterface)=> {
    this.chartOptions = charts.generateChartOptions(chartData?.drillGraph);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
